<template>
  <div class="indemnite-kilometrique-list">
    <PageHeader
      title="Histoire d'indemnite kilometrique"
      :has-back="true"
      @back="goBack"
    />

    <sortable-list :items="data" :parameters="listParameters"> </sortable-list>
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      data: [],
      listParameters: [
        {
          name: "Username",
          key: "username",
        },
        {
          name: "Dernière MAJ",
          key: "date_updated",
        },
        {
          name: "Type de vehicule",
          key: "type_vehicule",
        },
        {
          name: "Km min",
          key: "km_min",
        },
        {
          name: "Km max",
          key: "km_max",
        },
        {
          name: "CV min",
          key: "cv_min",
        },
        {
          name: "CV max",
          key: "cv_max",
        },
        {
          name: "Coef",
          key: "coef",
        },
        {
          name: "Montant additionnel",
          key: "montant_additionnel",
        },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.push("/admin/calculators/indemnite-kilometrique/list");
    },
  },
  mounted() {
    calculatorsModel.getHistoryKilo().then((data) => {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZone: "Europe/Paris",
      };
      data.forEach(function(d, i) {
        d.date_updated = new Date(d.date_updated).toLocaleDateString(
          "fr-FR",
          options
        );
        data[i] = Object.assign({}, d, JSON.parse(d.old_data));
      });
      this.data = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.impot-revenu-list {
  .debug-log {
    max-height: 200px;
    overflow-y: scroll;
  }
}
</style>

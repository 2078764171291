<template>
  <div :class="pageHeaderClass" class="page-header">
    <div v-if="title" class="page-header__item page-header__title">
      <div class="page-header__actions page-header__back-button">
        <md-button v-if="hasBack" class="md-icon-button" @click="$emit('back')">
          <md-icon class="md-primary"> keyboard_arrow_left</md-icon>
        </md-button>

        <div class="page-title">
          {{ title }}
        </div>
      </div>

      <div v-if="hasActions" class="page-header__actions">
        <div v-if="hasSearch && searchBesideAction" class="page-search">
          <label>
            <input
              :value="query"
              class="page-search__input"
              type="text"
              @input="queryChange"
            />
          </label>

          <MeepIconSearchi class="page-search__icon" />
        </div>
        <slot></slot>
      </div>
    </div>

    <div
      v-if="hasSearch || tabs.length >= 0"
      :class="{ 'page-header__tabs-search': tabs.length > 0 }"
      class="page-header__item"
    >
      <div v-if="tabs.length > 0" class="page-header__item-tabs">
        <md-tabs
          v-if="tabs.length"
          :md-active-tab="activeTab"
          :md-is-rtl="true"
          :md-sync-route="syncTab"
          @md-changed="changeTab"
        >
          <md-tab
            v-for="tab in tabs"
            :id="tab.id"
            :key="tab.id"
            :md-label="tab.label"
            :to="tab.to"
          />
        </md-tabs>
        <slot name="switch"></slot>
      </div>
      <div class="page-header__utils">
        <slot name="left"></slot>
        <div
          v-if="hasSearch && !searchBesideAction"
          class="page-header__search"
        >
          <div class="page-search">
            <label>
              <input
                :value="query"
                class="page-search__input"
                type="text"
                @input="queryChange"
              />
            </label>

            <MeepIconSearchi class="page-search__icon" />
          </div>
        </div>
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import MeepIconSearchi from "@/components/icons/MeepIconSearchi";
import { mapGetters } from "vuex";

export default {
  name: "PageHeader",

  components: {
    MeepIconSearchi,
  },

  model: {
    prop: "query",
    event: "input",
  },

  props: {
    title: {
      type: String,
      required: false,
    },

    hasBack: {
      type: Boolean,
      default: false,
    },

    hasSearch: {
      type: Boolean,
      default: false,
    },

    query: {
      type: String,
      default: "",
    },

    tabs: {
      type: Array,
      default: () => [],
    },

    activeTab: {
      type: String,
      default: null,
    },

    syncTab: {
      type: Boolean,
      default: false,
    },

    hasActions: {
      type: Boolean,
      default: false,
    },

    pagination: {
      type: Boolean,
      default: false,
    },

    searchBesideAction: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(["isMobile", "isJEPA", "isJEP", "isLCM"]),

    pageHeaderClass() {
      return {
        "page-header--mobile": this.isMobile,
        "page-header--jepa": this.isJEPA,
        "page-header--jep": this.isJEP,
        "page-header--lcm": this.isLCM,
      };
    },
  },

  methods: {
    changeTab(tabId) {
      this.$emit("change-tab", tabId);
    },

    queryChange(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_variable.scss";

#app {
  .page-header {
    width: 100%;
    margin-bottom: toRem(15);
    padding: 0 toRem(19) 0 toRem(29);
    @include for-lg {
      padding: 0 toRem(34) 0 toRem(29);
      margin-bottom: toRem(30);
    }

    .page-header__back-button {
      gap: toRem(8);
      @include for-lg{
        gap: 1rem;
      }
      .md-button {
        width: unset;
        min-width: unset;

        .md-ripple {
          width: fit-content;
          padding: 0;
          margin: 0;
        }

        &::before {
          display: none;
        }
      }

      .md-theme-default {
        margin-left: 0;
        .md-icon.md-primary {
          width: var(--back-button-size-lg);
          height: var(--back-button-size-lg);
          border-radius: 50%;
          padding: 0;
          @include for-lg {
            width: var(--back-button-size-xl);
            height: var(--back-button-size-xl);
          }
        }
      }
    }

    .page-header__actions {
      .page-header__item {
        margin-bottom: 0;
        align-items: flex-start;

        .md-button {
          min-width: unset;
          width: var(--header-icon-size-lg);
          height: var(--header-icon-size-lg);
          min-height: unset;
          @include for-lg {
            width: var(--header-icon-size-xl);
            height: var(--header-icon-size-xl);
          }

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .page-header__actions {
      .md-button.header-action-icon {
        min-width: unset;
        width: var(--header-icon-size-lg);
        height: var(--header-icon-size-lg);
        min-height: unset;
        @include for-lg {
          width: var(--header-icon-size-xl);
          height: var(--header-icon-size-xl);
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    @include for-lg {
      padding: 0 44px 0 54px;
    }

    &.page-header--jep {
      .md-button-content {
        .md-icon {
          background-color: var(--bg-alternate) !important;
        }
      }
    }

    &.page-header--jep {
      .md-button-content {
        .md-icon {
          background-color: var(--bg-alternate) !important;
        }
      }
    }

    &.page-header--jep {
      .md-button-content {
        .md-icon {
          background-color: var(--bg-alternate) !important;
        }
      }
    }

    &.page-header--jepa {
      font-family: Gotham-Bold, serif;
      font-size: 25px;
      font-weight: bold;
      color: #003382;

      .page-search__input {
        color: #003382;
      }
    }

    &--mobile {
      .page-title {
        line-height: 1.2;
      }

      .page-header__item {
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        height: auto;
        padding: 0;
      }

      .page-header__actions {
        margin-bottom: 10px;
        justify-content: flex-start;
        flex-wrap: wrap;

        .md-button {
          margin-right: 20px;
          margin-bottom: 0;
          margin-left: 0;
        }
      }

      .page-search {
        margin-top: 1rem;
      }
    }

    .page-header__item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .md-tabs-navigation {
        display: flex;
        gap: 1rem;
      }

      .md-tabs.md-theme-default .md-tab-nav-button {
        margin: 0;
      }
    }

    &__tabs-search {
      margin: 0;
    }

    &__title {
      margin-bottom: 20px;
      @include for-lg {
        margin-bottom: 43px;
      }
    }

    &__search {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .page-search {
      flex-shrink: 1;
      flex-grow: 0;
      flex-basis: 30%;
      height: 26px;
      position: relative;

      @include for-lg {
        height: 40px;
      }

      &__input {
        width: 283px;
        height: 100%;
        margin-right: 8px;
        font-size: 15px;
        color: var(--text-inverted, #000);
        padding: 7px 25px 7px 14px;
        border-radius: 5px;
        background-color: white;
        border: none;
        box-shadow: none;
        @include for-lg {
          width: 455px;
        }
      }

      &__icon {
        position: absolute;
        width: 12px;
        height: 12px;
        right: 15px;
        top: 8px;
        color: var(--bg-primary);
        @include for-lg {
          width: 20px;
          height: 20px;
          top: 10px;
        }

        g {
          stroke: var(--bg-primary);
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: toRem(8);

      .page-search {
        min-width: 450px;
        margin-right: 20px;
      }

      .header-action {
        margin: 0;
      }
    }

    &__utils {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: flex-end;

      .md-button {
        min-width: unset;
        min-height: unset;
        width: var(--header-icon-size-lg);
        height: var(--header-icon-size-lg);
        @include for-lg {
          width: var(--header-icon-size-xl);
          height: var(--header-icon-size-xl);
        }
      }
    }

    .page-header__item-tabs {
      display: flex;
      gap: 2rem;

      .md-ripple {
        padding: 0 1rem;
      }
    }
  }
}
</style>

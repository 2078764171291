import fetch from "../services/fetch";
import store from "../store";

export default {
  checkUpdates,
  getNumbers,
  setNumbers,

  //revenu
  getAllRevenu,
  createRevenu,
  getRevenu,
  updateRevenu,
  removeRevenu,
  getHistoryRevenu,

  //societe
  createSocietes,
  updateSocietes,
  getSocietes,
  getAllSocietes,
  removeSocietes,
  getHistorySocietes,

  //kilometrique
  createKilo,
  getAllKilo,
  getKilo,
  updateKilo,
  removeKilo,
  getHistoryKilo,

  //salaire
  createSalaire,
  getAllSalaire,
  getSalaire,
  updateSalaire,
  removeSalaire,
  getHistorySalaire,

  //carburant
  createCarburant,
  getAllCarburant,
  getCarburant,
  updateCarburant,
  removeCarburant,
  getHistoryCarburant,

  //emission
  createEmission,
  getAllEmission,
  getEmission,
  updateEmission,
  removeEmission,
  getHistoryEmission,
};

function checkUpdates() {
  return fetch("api/calculators/check-updates", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getNumbers(law, updateDate) {
  const queryParams = `?loi=${law}&date_maj=${updateDate}`;

  return fetch("api/calculators/getNumbers" + queryParams, {
    method: "get",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
  });
}

function setNumbers(law, updateDate) {
  return fetch("api/calculators/set-numbers", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "content-type": "application/json",
    },
    body: JSON.stringify({ loi: law, date_maj: updateDate }),
  });
}

//partie revenu

function createRevenu(calculator) {
  return fetch("api/calculators/", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "content-type": "application/json",
    },
    body: JSON.stringify(calculator),
  });
}
function getRevenu(id) {
  return fetch("api/calculators/get-impot-revenu/" + id, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getAllRevenu() {
  return fetch("api/calculators/get-all-impot-revenu/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}
function updateRevenu(impot_revenu) {
  return fetch("api/calculators/" + impot_revenu.id, {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(impot_revenu),
  });
}
function removeRevenu(id) {
  return fetch("api/calculators/" + id, {
    method: "delete",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
  });
}

function getHistoryRevenu() {
  return fetch("api/calculators/get-history-impot-revenu/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

//partie impot societes

function createSocietes(calculator) {
  return fetch("api/calculators/create-impot-societes/", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "content-type": "application/json",
    },
    body: JSON.stringify(calculator),
  });
}

function getSocietes(id) {
  return fetch("api/calculators/get-impot-societes/" + id, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getAllSocietes() {
  return fetch("api/calculators/get-all-impot-societes/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function updateSocietes(impot_societes) {
  return fetch("api/calculators/update-impot-societes/" + impot_societes.id, {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(impot_societes),
  });
}
function removeSocietes(id) {
  return fetch("api/calculators/remove-impot-societes/" + id, {
    method: "delete",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
  });
}
function getHistorySocietes() {
  return fetch("api/calculators/get-history-impot-societes/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

//patie kilometrique

function createKilo(calculator) {
  return fetch("api/calculators/create-kilometrique/", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "content-type": "application/json",
    },
    body: JSON.stringify(calculator),
  });
}
function getAllKilo() {
  return fetch("api/calculators/get-all-kilometrique/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}
function getKilo(id) {
  return fetch("api/calculators/get-kilometrique/" + id, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function updateKilo(update_kilo) {
  return fetch("api/calculators/update-kilometrique/" + update_kilo.id, {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(update_kilo),
  });
}
function removeKilo(id) {
  return fetch("api/calculators/remove-kilometrique/" + id, {
    method: "delete",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
  });
}
function getHistoryKilo() {
  return fetch("api/calculators/get-history-kilometrique/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

//partie salaire

function createSalaire(calculator) {
  return fetch("api/calculators/create-salaire/", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "content-type": "application/json",
    },
    body: JSON.stringify(calculator),
  });
}
function getAllSalaire() {
  return fetch("api/calculators/get-all-salaire/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}
function getSalaire(id) {
  return fetch("api/calculators/get-salaire/" + id, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function updateSalaire(salaire) {
  return fetch("api/calculators/update-salaire/" + salaire.id, {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(salaire),
  });
}
function removeSalaire(id) {
  return fetch("api/calculators/remove-salaire/" + id, {
    method: "delete",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
  });
}

function getHistorySalaire() {
  return fetch("api/calculators/get-history-salaire/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

//carburant partie

function createCarburant(carburant) {
  return fetch("api/calculators/create-carburant/", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "content-type": "application/json",
    },
    body: JSON.stringify(carburant),
  });
}

function getAllCarburant() {
  return fetch("api/calculators/get-all-carburant/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getCarburant(id) {
  return fetch("api/calculators/get-carburant/" + id, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function updateCarburant(carburant) {
  return fetch("api/calculators/update-carburant/" + carburant.id, {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(carburant),
  });
}

function removeCarburant(id) {
  return fetch("api/calculators/remove-carburant/" + id, {
    method: "delete",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
  });
}
function getHistoryCarburant() {
  return fetch("api/calculators/get-history-carburant/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

//emission partie

function createEmission(emission) {
  return fetch("api/calculators/create-emission/", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "content-type": "application/json",
    },
    body: JSON.stringify(emission),
  });
}

function getAllEmission() {
  return fetch("api/calculators/get-all-emission/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function getEmission(id) {
  return fetch("api/calculators/get-emission/" + id, {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}

function updateEmission(emission) {
  return fetch("api/calculators/update-emission/" + emission.id, {
    method: "put",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(emission),
  });
}

function removeEmission(id) {
  return fetch("api/calculators/remove-emission/" + id, {
    method: "delete",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
  });
}

function getHistoryEmission() {
  return fetch("api/calculators/get-history-emission/", {
    method: "get",
    headers: { Authorization: store.state.token },
  });
}
